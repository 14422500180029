'use client';
import { useEffect } from 'react';
import Image from "next/image";
import Link from "next/link";

export default function NotFound() {

  useEffect( () => {
    (
      async () => {
          const LocomotiveScroll = (await import('locomotive-scroll')).default
          const locomotiveScroll = new LocomotiveScroll();
      }
    )()
  }, [])

  return (
    <>
      <section className='empty-page section-padding-top d-flex justify-content-center align-items-center'>
        <div className='small-wrap text-center'>
          <Image  
            src="/not.png"
            width={600}
            height={600}
            alt="Scroll Down"
            className='not-found-img'
          />
          <div className="the_content_div heading-padding">
            <p className='m-0'>You may have typed the web address incorrectly.</p>
            <p className='m-0'>Please check the address and spelling ensuring that it does not contain spaces.</p>
            <p className='m-0'> An incorrect link was given either on an internal or external page.</p>
            <p className=''>It is possible that the page you were looking for may have been moved, updated or deleted.</p>
            <h2 className='m-0'> Please make sure you have refreshed/reloaded the page you were on before this one.</h2>
          </div>
          <div className='section-padding-bottom'>
            <Link className="next-btn next-btn--yellow m-auto" href="/">
              <span className="next-btn--text">Back to home</span>
              <div className="next-btn--bottom">
                <span className="next-btn--bottom-line"></span>
                <span className="next-btn--bottom-square"></span>
                <span className="next-btn--bottom-line"></span>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}